import { provideEffects } from "@ngrx/effects";
import { provideState } from "@ngrx/store";
import type { EnvironmentProviders, Provider } from "@angular/core";
import { LoginEffects } from "./+state/login.effects";
import * as fromLogin from "./+state/login.reducer";
import { LOGIN_FEATURE_KEY } from "./+state/login.reducer";

export function provideMyCarglassLogin(): Provider[] | EnvironmentProviders[] {
  return [provideState(LOGIN_FEATURE_KEY, fromLogin.reducer), provideEffects([LoginEffects])];
}
