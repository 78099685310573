import { AsyncPipe } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from "@angular/core";
import { Observable, of } from "rxjs";
import { switchMap, take } from "rxjs/operators";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { HeadlineComponent, HeadlineType, IconComponent, ParagraphComponent } from "@cg/core/ui";
import { errorIcon, infoYellowIcon, successIcon } from "@cg/icon";
import { ToastPopupComponent } from "@cg/shared";
import type { Icon } from "@cg/content-api/typescript-interfaces";
import { LoginFacade } from "../../../../+state/login.facade";
import { MyCarglassNotificationStatus } from "../../../../enums";

@Component({
  selector: "cg-my-carglass-status-toast",
  templateUrl: "./my-carglass-status-toast.component.html",
  styleUrls: ["./my-carglass-status-toast.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [AsyncPipe, TranslocoPipe, ToastPopupComponent, IconComponent, HeadlineComponent, ParagraphComponent]
})
export class MyCarglassStatusToastComponent implements OnInit {
  public readonly emailAddress$ = this.loginFacade.emailAddress$;
  public readonly HeadlineType = HeadlineType;
  public readonly MyCarglassNotificationStatus = MyCarglassNotificationStatus;

  @Input()
  public toastStatus: MyCarglassNotificationStatus;

  @Output() public closeClicked = new EventEmitter<void>();

  public hideCloseIcon = true;

  public icon: Icon;
  public headlineKey: string;
  public message$: Observable<string>;

  public constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly loginFacade: LoginFacade,
    private readonly translocoService: TranslocoService
  ) {}

  public ngOnInit(): void {
    this.hideCloseIcon = this.toastStatus !== MyCarglassNotificationStatus.LOGOUT_INACTIVITY;
    this.headlineKey = this.mapHeadlineKey();
    this.message$ = this.mapMessage$();
    this.icon = this.mapIconSvgName();
    this.cdr.detectChanges();
  }

  public onCloseClicked(): void {
    this.closeClicked.emit();
  }

  private mapIconSvgName(): Icon {
    switch (this.toastStatus) {
      case MyCarglassNotificationStatus.ERROR:
        return errorIcon;
      case MyCarglassNotificationStatus.SUCCESS:
      case MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_SUCCESS:
      case MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_AGAIN:
      case MyCarglassNotificationStatus.LOGOUT:
        return successIcon;
      case MyCarglassNotificationStatus.LOGOUT_INACTIVITY:
      case MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_NOT_POSSIBLE:
      case MyCarglassNotificationStatus.WRONG_LPN:
      case MyCarglassNotificationStatus.WRONG_KTCI:
        return infoYellowIcon;
    }
  }

  private mapHeadlineKey(): string {
    const textKeyBegin = "myCarglass.statusToast";

    switch (this.toastStatus) {
      case MyCarglassNotificationStatus.ERROR:
        return `${textKeyBegin}.errorHeadline`;
      case MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_SUCCESS:
      case MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_AGAIN:
        return `${textKeyBegin}.mailSentHeadline`;
      case MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_NOT_POSSIBLE:
        return `${textKeyBegin}.mailSentNotPossibleHeadline`;
      case MyCarglassNotificationStatus.SUCCESS:
        return `${textKeyBegin}.successHeadline`;
      case MyCarglassNotificationStatus.LOGOUT:
        return `${textKeyBegin}.logout`;
      case MyCarglassNotificationStatus.LOGOUT_INACTIVITY:
        return `${textKeyBegin}.logoutInactivity`;
      case MyCarglassNotificationStatus.WRONG_LPN:
      case MyCarglassNotificationStatus.WRONG_KTCI:
        return `${textKeyBegin}.attemptsTitle`;
      default:
        return "";
    }
  }

  private mapMessage$(): Observable<string> {
    const textKeyBegin = "myCarglass.statusToast";

    switch (this.toastStatus) {
      case MyCarglassNotificationStatus.ERROR:
        return this.translocoService.selectTranslate(`${textKeyBegin}.errorMessage`);
      case MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_SUCCESS:
      case MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_AGAIN:
        return this.translocoService.selectTranslate(`${textKeyBegin}.mailSentMessage`);
      case MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_NOT_POSSIBLE:
        return this.translocoService.selectTranslate(`${textKeyBegin}.mailSentNotPossibleMessage`);
      case MyCarglassNotificationStatus.WRONG_LPN:
        return this.loginFacade.licencePlateNumberCheckFailed$.pipe(
          take(1),
          switchMap((attempts: number | null) =>
            this.translocoService.selectTranslate(`${textKeyBegin}.attemptsMessage`, { attempts })
          )
        );
      case MyCarglassNotificationStatus.WRONG_KTCI:
        return this.loginFacade.loginWithKtciCodeFailed$.pipe(
          take(1),
          switchMap((attempts: number | null) =>
            this.translocoService.selectTranslate(`${textKeyBegin}.attemptsMessage`, { attempts })
          )
        );
      default:
        return of("");
    }
  }
}
