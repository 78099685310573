import { NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Inject,
  Input,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { filter } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { ApiError, ResumeFacade } from "@cg/resume-core";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { CookieService } from "ngx-cookie-service";
import { GoogleTagManagerService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { HeadlineComponent, HeadlineType, ParagraphComponent } from "@cg/core/ui";
import { LpnInputComponent } from "@cg/lpn-input";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { ComponentOverarchingChangeDetectionService, CtaComponent, ErrorMessageComponent, Resume } from "@cg/shared";
import type { Cta } from "@cg/content-api/typescript-interfaces";
import { LoginFacade } from "../../../../+state/login.facade";
import { LoginLpnForm } from "../../../../interfaces";
import { LoginDirective } from "../../login-directive/login.directive";
import { textsDirectResume } from "../../models/direct-resume.model";
import { lpnCtaLogin, textsLogin } from "../../models/login.model";
import { lpnCtaSaveAndRestore, textsSaveAndRestore } from "../../models/save-and-restore.model";

@Component({
  selector: "cg-license-plate",
  templateUrl: "./license-plate.component.html",
  styleUrls: ["./license-plate.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    TranslocoPipe,
    ReactiveFormsModule,
    OlbHeadlineComponent,
    HeadlineComponent,
    ParagraphComponent,
    LpnInputComponent,
    ErrorMessageComponent,
    CtaComponent
  ]
})
export class LicensePlateComponent extends LoginDirective<AddFormControls<LoginLpnForm>> implements OnInit {
  public destroyRef = inject(DestroyRef);
  public readonly HeadlineType = HeadlineType;

  @Input()
  public resumeId = "";

  public hasResumeId = false;

  public texts: {
    title: string;
    subtitle?: string;
    description?: string;
  } = textsSaveAndRestore;

  public lpnCta: Cta = lpnCtaSaveAndRestore;

  // eslint-disable-next-line max-params
  public constructor(
    protected readonly changeDetection: ChangeDetectorRef,
    protected readonly translocoService: TranslocoService,
    protected readonly gtmService: GoogleTagManagerService,
    private readonly router: Router,
    private readonly loginFacade: LoginFacade,
    private readonly resumeFacade: ResumeFacade,
    private readonly changeDetectionService: ComponentOverarchingChangeDetectionService,
    private readonly cookieService: CookieService,
    @Inject(OLB_CONFIG) private readonly olbConfig: OlbConfiguration
  ) {
    super(changeDetection, translocoService, gtmService);
  }

  public ngOnInit() {
    super.ngOnInit();
    this.hasResumeId = !!this.resumeId;

    if (!this.hasResumeId) {
      this.texts = textsLogin;
      this.lpnCta = lpnCtaLogin;
    } else if (this.router.url.includes("direct-resume")) {
      this.texts = textsDirectResume;
    }

    this.resumeFacade.loadFailure$
      .pipe(
        filter((error: ApiError) => !!error),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe((error: ApiError) => {
        this.trackNextEvent(false);

        if (!error) {
          return;
        }

        this.cookieService.delete("resumeId");

        const errorPath = "/login/error";

        if (error.status === 410 || error.status === 404) {
          this.router.navigate([errorPath]);
        } else if (error.status === 401) {
          this.resumeFacade.resetState();
          const errorBody = error.body as { failedAttempts: number };
          const translationKey = this.hasResumeId
            ? "myCarglass.login.saveAndRestore.attempts"
            : "myCarglass.login.attempts";
          this.errorMessage = this.translocoService.translate(translationKey, {
            attempts: this.resumeAttempts - errorBody.failedAttempts
          });
          this.changeDetection.markForCheck();
        } else {
          this.router.navigate([errorPath]);
          this.errorMessage = this.translocoService.translate("myCarglass.login.failed");
        }
      });

    this.resumeFacade.loadSuccess$
      .pipe(
        filter((result: Resume) => !!result),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe(() => {
        this.router.navigate(["/olb"]);
        this.trackNextEvent(true);
      });
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<LoginLpnForm>>({
      lpn: new FormControl(null, Validators.required)
    });
  }

  public onSubmit() {
    this.form.markAllAsTouched();
    this.changeDetectionService.changeDetectionRequest$.next();

    if (this.form.invalid) {
      this.trackNextEvent(false);
      return;
    }
    const lpn = this.form.controls.lpn.value;
    if (!this.resumeId) {
      this.loginFacade.checkLicensePlateNumber(lpn);
    } else {
      this.resumeFacade.loadResume(this.resumeId, lpn);
    }
  }

  public trackNextEvent(success: boolean) {
    const eventLabel = success ? "next/success" : "next/fail";
    if (this.hasResumeId) {
      this.trackEvent(eventLabel, "log-in-license-plate", "olb");
    } else {
      this.trackEvent(eventLabel);
    }
  }
}
