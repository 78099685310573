import { arrowsIcon } from "@cg/icon";
import { CtaVariation } from "@cg/shared";
import type { Cta } from "@cg/content-api/typescript-interfaces";

export const textsSaveAndRestore = {
  title: "myCarglass.login.saveAndRestore.titleResume",
  description: "myCarglass.login.saveAndRestore.descriptionResume"
};

const title = "Weiter";
export const lpnCtaSaveAndRestore: Cta = {
  id: "login-cta",
  title,
  icon: arrowsIcon,
  link: {
    href: null,
    title,
    text: title,
    routerLink: false
  },
  arrowDirection: "right",
  variation: CtaVariation.PRIMARY,
  ngTemplate: "cgCta"
};
