import { AsyncPipe } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from "@angular/core";
import { FormGroup, ReactiveFormsModule, UntypedFormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { ktciValidators } from "@cg/validators";
import { TranslocoPipe, TranslocoService } from "@jsverse/transloco";
import { GoogleTagManagerService } from "@cg/analytics";
import { AddFormControls } from "@cg/core/types";
import { HeadlineComponent, HeadlineType, ParagraphComponent } from "@cg/core/ui";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { CtaComponent, InputType, TextInputComponent } from "@cg/shared";
import type { Cta } from "@cg/content-api/typescript-interfaces";
import { LoginFacade } from "../../../../+state/login.facade";
import { LoginKtciForm } from "../../../../interfaces";
import { LoginDirective } from "../../login-directive/login.directive";
import { lpnCtaLogin, textsLoginKtci } from "../../models/login.model";

@Component({
  selector: "cg-verification-code",
  templateUrl: "./verification-code.component.html",
  styleUrls: ["./verification-code.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    AsyncPipe,
    TranslocoPipe,
    ReactiveFormsModule,
    OlbHeadlineComponent,
    HeadlineComponent,
    ParagraphComponent,
    TextInputComponent,
    CtaComponent
  ]
})
export class VerificationCodeComponent extends LoginDirective<AddFormControls<LoginKtciForm>> {
  public readonly HeadlineType = HeadlineType;

  protected readonly InputType = InputType;

  @Input()
  public resumeId = "";

  public ktciInput = {
    id: "ktci",
    controlName: "ktci",
    placeholder: "myCarglass.login.ktciInput.placeholder",
    errorMessage: "myCarglass.login.ktciInput.error"
  };

  public texts: {
    title: string;
    subtitle?: string;
    description: string;
  } = textsLoginKtci;

  public lpnCta: Cta = lpnCtaLogin;

  public recipientEmail$: Observable<string> = this.loginFacade.emailAddress$;

  public constructor(
    protected readonly changeDetection: ChangeDetectorRef,
    protected readonly translocoService: TranslocoService,
    protected readonly gtmService: GoogleTagManagerService,
    private readonly loginFacade: LoginFacade
  ) {
    super(changeDetection, translocoService, gtmService);
  }

  public initFormGroup(): void {
    this.form = new FormGroup<AddFormControls<LoginKtciForm>>({
      ktci: new UntypedFormControl(null, ktciValidators())
    });
  }

  public onSubmit() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.trackEvent("next/fail", "log-in-code");
      return;
    }
    this.loginFacade.loginWithKtciCodeButtonClicked(this.resumeId, this.form.controls.ktci.value);
  }
}
