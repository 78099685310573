<div class="flex flex-col" [formGroup]="form">
  <cg-olb-headline
    class="text-center [&>h1]:!mt-0 [&>h1]:!font-normal"
    [type]="'h1'"
    [title]="texts.title | transloco"
  ></cg-olb-headline>

  @if (texts.subtitle) {
    <cg-headline class="mb-2 mt-3" [type]="HeadlineType.h4" [content]="texts.subtitle | transloco"></cg-headline>
  }
  @if (texts.description) {
    <cg-paragraph
      [content]="{ text: texts.description | transloco, ngTemplate: 'cgParagraph' }"
      class="mx-auto max-w-[350px] text-center d:max-w-[320px]"
    ></cg-paragraph>
  }
  <cg-headline
    class="mb-2 mt-4 part-[headline]:text-[13px] part-[headline]:leading-[20px]"
    [type]="HeadlineType.h4"
    [content]="'myCarglass.login.lpnInput' | transloco"
  ></cg-headline>

  <div class="flex flex-col items-center">
    <cg-lpn-input class="licence-plate--login-lpn-input mb-2 w-full" (keyup.enter)="onSubmit()"></cg-lpn-input>

    @if (form.controls.lpn.touched && form.controls.lpn.hasError("invalid")) {
      <cg-error-message [errorMessage]="'licensePlate.licensePlate.errorMessage' | transloco"></cg-error-message>
    }
    @if (errorMessage) {
      <cg-error-message [errorMessage]="errorMessage"></cg-error-message>
    }

    <cg-cta
      class="!w-full [&>div>div>div]:text-base [&>div]:w-full"
      [content]="lpnCta"
      (clickedFn)="onSubmit()"
      data-cy="my-carglass-license-plate-submit-button"
    ></cg-cta>
  </div>
</div>
