import { AsyncPipe, NgClass } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { ActivatedRoute } from "@angular/router";
import { Observable } from "rxjs";
import { filter, tap } from "rxjs/operators";
import { TranslocoPipe } from "@jsverse/transloco";
import { CookieService } from "ngx-cookie-service";
import { GoogleTagManagerService, Page } from "@cg/analytics";
import { PictureComponent } from "@cg/core/ui";
import { OverlayService } from "@cg/shared";
import { LoginFacade } from "../../+state/login.facade";
import { MyCarglassNotificationStatus } from "../../enums";
import { LicensePlateComponent } from "./components/license-plate/license-plate.component";
import { LoginProblemsOverlayComponent } from "./components/login-problems-overlay/login-problems-overlay.component";
import { MyCarglassStatusToastComponent } from "./components/my-carglass-status-toast/my-carglass-status-toast.component";
import { VerificationCodeComponent } from "./components/verification-code/verification-code.component";
import { imageContentLogin } from "./models/login.model";

@Component({
  selector: "cg-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgClass,
    AsyncPipe,
    TranslocoPipe,
    MyCarglassStatusToastComponent,
    PictureComponent,
    LicensePlateComponent,
    VerificationCodeComponent
  ]
})
export class LoginComponent implements OnInit, OnDestroy {
  public destroyRef = inject(DestroyRef);
  public resumeId = "";
  public isKtciContent$ = this.loginFacade.isKtciContent$;

  public imageContent: {
    sizes: { srcset: string; media: string }[];
    source: string;
    classes: string;
    alt: string;
    ngTemplate: string;
  } = imageContentLogin;

  public notificationStatus$: Observable<MyCarglassNotificationStatus>;

  public constructor(
    private readonly route: ActivatedRoute,
    private readonly changeDetection: ChangeDetectorRef,
    private readonly loginFacade: LoginFacade,
    private readonly overlayService: OverlayService,
    private readonly cookieService: CookieService,
    private readonly gtmService: GoogleTagManagerService
  ) {}

  public ngOnInit(): void {
    let page: Page = {
      name: "login",
      type: "login",
      variant: "save-and-restore"
    };
    this.resumeId = this.route.snapshot.params["resume-id"];

    if (!this.resumeId) {
      page = {
        name: "login",
        type: "login",
        variant: "my-carglass"
      };
      this.loginFacade.licencePlateNumberCheckSuccess$
        .pipe(
          filter((resumeId: string) => !!resumeId),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe((resumeId: string) => {
          this.resumeId = resumeId;
          this.changeDetection.detectChanges();
        });
    } else if (this.route.snapshot?.url[0]?.path === "direct-resume") {
      page = {
        name: "login",
        type: "login",
        variant: "direct-resume"
      };
      this.cookieService.set("resumeId", this.resumeId);
    }

    this.gtmService.trackPageView(page);
    this.handleNotificationStatus();
  }

  public ngOnDestroy(): void {
    this.closeToast();
  }

  public openLoginProblemsOverlay(): void {
    this.overlayService.open(LoginProblemsOverlayComponent);
  }

  public closeToast(): void {
    this.loginFacade.setNotificationStatus(null);
  }

  public sendKtciMailAgain(): void {
    this.loginFacade.sendKtciMailAgain();
  }

  private handleNotificationStatus(): void {
    this.notificationStatus$ = this.loginFacade.notificationStatus$.pipe(
      tap((status: MyCarglassNotificationStatus) => {
        if (status && status !== MyCarglassNotificationStatus.LOGOUT_INACTIVITY) {
          const timeout = status !== MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_NOT_POSSIBLE ? 4000 : 8000;
          setTimeout(() => {
            this.closeToast();
          }, timeout);
        }
      }),
      filter(
        (status: MyCarglassNotificationStatus) => status !== MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_SUCCESS
      ),
      takeUntilDestroyed(this.destroyRef)
    );
  }
}
