export enum MyCarglassNotificationStatus {
  SUCCESS = "SUCCESS",
  ERROR = "ERROR",
  KTCI_MAIL_DELIVERY_SUCCESS = "KTCI_MAIL_DELIVERY_SUCCESS",
  KTCI_MAIL_DELIVERY_NOT_POSSIBLE = "KTCI_MAIL_DELIVERY_NOT_POSSIBLE",
  KTCI_MAIL_DELIVERY_AGAIN = "KTCI_MAIL_DELIVERY_AGAIN",
  LOGOUT = "LOGOUT",
  LOGOUT_INACTIVITY = "LOGOUT_INACTIVITY",
  WRONG_LPN = "WRONG_LPN",
  WRONG_KTCI = "WRONG_KTCI"
}
