import { environment } from "@cg/environments";
import { arrowsIcon } from "@cg/icon";
import { CtaVariation } from "@cg/shared";
import type { Cta } from "@cg/content-api/typescript-interfaces";

export const textsLogin = {
  title: "myCarglass.login.titleLogin",
  description: "myCarglass.login.descriptionLogin"
};

export const textsLoginKtci = {
  title: "myCarglass.login.titleLogin",
  description: "myCarglass.login.descriptionLoginKtci"
};

export const lpnCtaLogin: Cta = {
  id: "login-cta",
  title: "Anmelden",
  icon: arrowsIcon,
  link: {
    href: null,
    title: "Anmelden",
    text: "Anmelden",
    routerLink: false
  },
  arrowDirection: "right",
  variation: CtaVariation.PRIMARY,
  ngTemplate: "cgCta"
};

export const ktciInput = {
  id: "customer-address-firstname",
  controlName: "firstname",
  placeholder: "customerAddress.inputs.firstname.placeholder",
  errorMessage: "customerAddress.inputs.firstname.error"
};

export const imageContentLogin = {
  sizes: [
    {
      srcset: `${environment.assetPath}/my-carglass/my-carglass-login-mobile-360.png`,
      media: "xs"
    },
    {
      srcset: `${environment.assetPath}/my-carglass/my-carglass-login-tablet-675.png`,
      media: "sm"
    },
    {
      srcset: `${environment.assetPath}/my-carglass/my-carglass-login-desktop-768.png`,
      media: "md"
    },
    {
      srcset: `${environment.assetPath}/my-carglass/my-carglass-login-widescreen-992.png`,
      media: "lg"
    },
    {
      srcset: `${environment.assetPath}/my-carglass/my-carglass-login-widescreen-1200.png`,
      media: "xl"
    }
  ],
  source: `${environment.assetPath}/my-carglass/my-carglass-login-widescreen-1200.png`,
  classes: "cls-1 cls-2",
  alt: "Alternative text",
  ngTemplate: "cgPicture"
};
