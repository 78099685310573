import { ChangeDetectionStrategy, Component, DestroyRef, inject, Inject, OnDestroy, OnInit } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Router } from "@angular/router";
import { combineLatest } from "rxjs";
import { filter, map, take, tap } from "rxjs/operators";
import { OLB_CONFIG, OlbConfiguration } from "@cg/olb/configuration";
import { ApiError, ResumeFacade } from "@cg/resume-core";
import { GoogleTagManagerService, Page } from "@cg/analytics";
import { environment } from "@cg/environments";
import { Button, EntryChannel, ErrorPageBaseComponent, PhoneNumber } from "@cg/shared";
import { LoginFacade, MY_CARGLASS_LOGIN_URL } from "../../../index";

@Component({
  selector: "cg-error",
  templateUrl: "./my-carglass-error.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ErrorPageBaseComponent]
})
export class MyCarglassErrorComponent implements OnInit, OnDestroy {
  private destroyRef = inject(DestroyRef);
  public readonly phoneNumber = PhoneNumber.MY_CARGLASS_LOGIN;

  public headline = "myCarglass.errorPage.loginNotPossible";
  public subHeadline = "myCarglass.errorPage.default.subHeadline";
  public errorMessage = "myCarglass.errorPage.default.errorMessage";
  public button: Button;
  public readonly phoneInfoTextKey: string = "myCarglass.errorPage.phoneInfoTextKey";

  public constructor(
    @Inject(OLB_CONFIG) private readonly olbConfig: OlbConfiguration,
    private resumeFacade: ResumeFacade,
    private readonly loginFacade: LoginFacade,
    private readonly router: Router,
    private readonly gtmService: GoogleTagManagerService
  ) {}

  public ngOnInit(): void {
    combineLatest([this.resumeFacade.loadFailure$, this.loginFacade.apiErrorStatusCode$])
      .pipe(
        take(1),
        map(
          ([resumeFailure, loginApiErrorStatusCode]: [ApiError, number]) =>
            resumeFailure?.status ?? loginApiErrorStatusCode
        ),
        tap((statusCode: number) => {
          let pageVariant = "my-carglass";
          switch (statusCode) {
            case 400:
              pageVariant = "resume-data-not-found";
              break;
            case 410: // link expired or authentication failed
              pageVariant = "resume-link-expired";
              this.errorMessage = "myCarglass.errorPage.expired.errorMessage";
              break;
            case 403:
            case 409: // already existing Appointment
              pageVariant = statusCode === 403 ? "max-ktci-code-requests-reached" : "appointment-already-exists";
              this.subHeadline = "myCarglass.errorPage.existingAppointment.subHeadline";
              this.errorMessage = "myCarglass.errorPage.existingAppointment.errorMessage";
              this.button = {
                text: "myCarglass.errorPage.existingAppointment.buttonText",
                id: "my-carglass-button"
              };
              break;
            case 406: // already existing channelSwitch
              pageVariant = "channel-switch-already-exists";
              this.subHeadline = "myCarglass.errorPage.existingChannelSwitch.subHeadline";
              this.errorMessage = "myCarglass.errorPage.existingChannelSwitch.errorMessage";
              break;
          }
          this.trackErrorPageView(pageVariant);
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    this.resumeFacade.resetState();
    this.loginFacade.resetState();
  }

  public buttonClicked() {
    if (this.olbConfig.entryChannel === EntryChannel.CARGLASS) {
      this.resumeFacade.resetState();

      this.resumeFacade.loadFailure$
        .pipe(
          filter((apiError: ApiError | null) => apiError === null),
          take(1),
          tap(() => {
            this.router.navigateByUrl(MY_CARGLASS_LOGIN_URL);
          }),
          takeUntilDestroyed(this.destroyRef)
        )
        .subscribe();
      return;
    }

    let baseURL = "https://www.carglass.de";
    switch (environment.envName) {
      case "awsdev":
        baseURL = "https://www.dev.services.carglass.de";
        break;
      case "awsuat":
        baseURL = "https://www.uat.services.carglass.de";
        break;
    }
    window.location.href = baseURL + MY_CARGLASS_LOGIN_URL;
  }

  private trackErrorPageView(variant: string): void {
    const page: Page = {
      name: "login/error",
      type: "olb",
      variant
    };

    this.gtmService.trackPageView(page);
  }
}
