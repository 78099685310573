import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ApiService } from "@cg/core/api";
import { Lpn } from "@cg/shared";
import { KtciResume, Login } from "../interfaces";

@Injectable({
  providedIn: "root"
})
export class LoginService {
  public constructor(private apiService: ApiService) {}

  public checkLicensePlateNumberCode(lpn: Lpn): Observable<Login> {
    const path = `/resume?lpn=${lpn.region}-${lpn.letters}-${lpn.numbers}`;
    return this.apiService.get<Login>("frontendProxy", path, { disableRetry: true });
  }

  public requestKtciResumeWithResumeIdAndKtciCode(resumeId: string, ktci: string): Observable<KtciResume> {
    const path = `/resume/${resumeId}?code=${ktci}`;
    return this.apiService.get<KtciResume>("frontendProxy", path, { disableRetry: true }).pipe(
      map(
        (response: KtciResume) =>
          ({
            ...response,
            state: !response.state ? null : JSON.parse(response.state)
          }) as KtciResume
      )
    );
  }
}
