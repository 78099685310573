<cg-toast-popup
  class="[&>div]:w-[calc(100%-2rem)] t:[&>div]:-top-12"
  [hasRoundBorders]="true"
  [hideCloseIcon]="hideCloseIcon"
  (closeClicked)="onCloseClicked()"
>
  <div class="flex items-center gap-4">
    <cg-icon class="h-8 w-8" [content]="icon"></cg-icon>
    @if (
      (toastStatus === MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_SUCCESS ||
        toastStatus === MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_AGAIN) &&
        emailAddress$ | async;
      as emailAddress
    ) {
      <cg-headline [type]="HeadlineType.h3" [content]="headlineKey | transloco: { emailAddress }"></cg-headline>
    } @else {
      <cg-headline [type]="HeadlineType.h3" [content]="headlineKey | transloco"></cg-headline>
    }
  </div>
  @if (message$ | async; as message) {
    <div class="flex items-start gap-4">
      <cg-icon class="h-8 w-8 opacity-0" [content]="icon"></cg-icon>
      <cg-paragraph>{{ message }}</cg-paragraph>
    </div>
  }
</cg-toast-popup>
