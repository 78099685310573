import { ChangeDetectionStrategy, Component } from "@angular/core";
import { TranslocoPipe } from "@jsverse/transloco";
import { ParagraphComponent } from "@cg/core/ui";
import { OlbHeadlineComponent } from "@cg/olb/shared";
import { CccPhoneInfoComponent, DrawerComponent, OverlayFooterComponent, PhoneNumber } from "@cg/shared";

@Component({
  selector: "cg-login-problems-overlay",
  templateUrl: "./login-problems-overlay.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    TranslocoPipe,
    DrawerComponent,
    OlbHeadlineComponent,
    ParagraphComponent,
    CccPhoneInfoComponent,
    OverlayFooterComponent
  ]
})
export class LoginProblemsOverlayComponent {
  public readonly PhoneNumber = PhoneNumber;
}
