@if (notificationStatus$ | async; as status) {
  <cg-my-carglass-status-toast [toastStatus]="status" (closeClicked)="closeToast()"></cg-my-carglass-status-toast>
}
@if ({ isKtciContent: isKtciContent$ | async }; as data) {
  <div
    class="relative pb-[581px] m:pb-[566px] t:pb-[554px] d:pb-[681px] w:mx-12 w:pb-[689px]"
    [ngClass]="{
      '!pb-[618px] m:!pb-[603px] t:!pb-[591px] d:!pb-[702px] w:!pb-[710px] fhd:!pb-[710px]': data.isKtciContent
    }"
  >
    <div class="absolute inset-y-0 left-1/2 w-full max-w-[75rem] -translate-x-1/2">
      <cg-picture
        class="absolute left-0 right-0 top-0 w:-left-12 w:-right-12 [&>picture>img]:h-[240px] [&>picture>img]:w-screen [&>picture>img]:object-cover d:[&>picture>img]:h-[400px]"
        [content]="imageContent"
      ></cg-picture>
      <div
        class="absolute left-1/2 top-[180px] flex w-[calc(100%-1rem)] -translate-x-1/2 flex-col items-center rounded-lg bg-cg-white p-4 shadow-card m:top-[165px] m:w-[calc(100%-2rem)] t:top-[177px] t:w-[calc(100%-3rem)] d:top-[240px] d:w-[432px] d:px-12 d:pb-8 d:pt-6"
        [ngClass]="{
          'top-[140px] m:top-[142px]  t:top-[162px]  d:top-[240px]': data.isKtciContent
        }"
      >
        @if (data.isKtciContent) {
          <cg-verification-code class="w-full" [resumeId]="resumeId"></cg-verification-code>
        } @else {
          <cg-license-plate class="w-full" [resumeId]="resumeId"></cg-license-plate>
        }
        <div class="-mx-4 mt-4 flex flex-col items-center gap-4" [ngClass]="{ 'd:mt-6': data.isKtciContent }">
          <a
            class="text-[14px] text-cg-anthracite underline"
            data-cy="my-carglass-login-problems-link"
            (click)="openLoginProblemsOverlay()"
            >{{ "myCarglass.login.passwordForgot" | transloco }}</a
          >
          @if (data.isKtciContent) {
            <a
              class="text-[14px] text-cg-anthracite underline"
              (click)="sendKtciMailAgain()"
              data-cy="my-carglass-login-send-ktci-again-link"
              >{{ "myCarglass.login.sendKtciCodeAgain" | transloco }}</a
            >
          }
        </div>
      </div>
    </div>
  </div>
}
