import { type Action, createReducer, on } from "@ngrx/store";
import type { KtciResume } from "../interfaces";
import type { Lpn, Resume } from "@cg/shared";
import { MyCarglassNotificationStatus } from "../enums/my-carglass-toast-status.enum";
import * as LoginActions from "./login.actions";

export const LOGIN_FEATURE_KEY = "myCarglassLogin";

export interface LoginState {
  resumeId: string | null;
  resume: Resume | null;
  lpn: Lpn;
  maxAttemptsLpn: number | null;
  maxAttemptsKtciCode: number | null;
  notificationStatus: MyCarglassNotificationStatus;
  ktciResume: KtciResume;
  isKtciContent: boolean;
  emailAddress: string | null;
  apiErrorStatusCode: number | null;
}

export interface LoginPartialState {
  readonly [LOGIN_FEATURE_KEY]: LoginState;
}

export const initialState: LoginState = {
  resumeId: null,
  resume: null,
  lpn: null,
  maxAttemptsLpn: null,
  maxAttemptsKtciCode: null,
  notificationStatus: null,
  ktciResume: null,
  isKtciContent: false,
  emailAddress: null,
  apiErrorStatusCode: null
};

const loginReducer = createReducer(
  initialState,
  on(
    LoginActions.checkLicensePlateNumber,
    (state: LoginState, action: { lpn: Lpn } & Action): LoginState => ({
      ...state,
      lpn: action.lpn
    })
  ),
  on(
    LoginActions.ktciAlreadyDeliverdAfterLpnCheck,
    (state: LoginState, action: { payload: { resumeId: string } } & Action): LoginState => ({
      ...state,
      resumeId: action.payload.resumeId,
      isKtciContent: true
    })
  ),
  on(
    LoginActions.licensePlateNumberCheckSuccessResultReceived,
    (state: LoginState, action: { resumeId: string } & Action): LoginState => ({
      ...state,
      resumeId: action.resumeId,
      isKtciContent: true
    })
  ),
  on(
    LoginActions.licensePlateNumberCheckFailureResultReceived,
    (
      state: LoginState,
      action: { payload: { maxAttempts: number; apiErrorStatusCode: number } } & Action
    ): LoginState => ({
      ...state,
      maxAttemptsLpn: action.payload.maxAttempts,
      apiErrorStatusCode: action.payload.apiErrorStatusCode
    })
  ),
  on(
    LoginActions.setResume,
    (state: LoginState, action: { resume: Resume } & Action): LoginState => ({
      ...state,
      resume: action.resume
    })
  ),
  on(
    LoginActions.loginWithKtciSuccessful,
    (state: LoginState, action: { payload: KtciResume } & Action): LoginState => ({
      ...state,
      ktciResume: action.payload
    })
  ),
  on(
    LoginActions.loginFailureResultReceived,
    (
      state: LoginState,
      action: { payload: { maxAttempts: number; apiErrorStatusCode: number } } & Action
    ): LoginState => ({
      ...state,
      maxAttemptsKtciCode: action.payload.maxAttempts,
      apiErrorStatusCode: action.payload.apiErrorStatusCode
    })
  ),
  on(
    LoginActions.resetState,
    (): LoginState => ({
      ...initialState
    })
  ),
  on(
    LoginActions.setNotificationStatus,
    (state: LoginState, action: { payload: MyCarglassNotificationStatus } & Action): LoginState => ({
      ...state,
      notificationStatus: action.payload
    })
  ),
  on(
    LoginActions.setNotificationStatusToMailDeliverySuccess,
    (state: LoginState, action: { payload: { emailAddress: string } } & Action): LoginState => ({
      ...state,
      notificationStatus: MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_SUCCESS,
      emailAddress: action.payload.emailAddress
    })
  )
);

export function reducer(state: LoginState, action: Action): LoginState {
  return loginReducer(state, action);
}
