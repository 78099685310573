import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Action, select, Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { catchError, map, switchMap, withLatestFrom } from "rxjs/operators";
import { AppointmentFacade, CustomerCaseActions, ProcessFacade } from "@cg/olb/state";
import { ResumeActions } from "@cg/resume-core";
import { GoogleTagManagerService } from "@cg/analytics";
import * as SharedActions from "@cg/carglass-shared-state";
import { CustomerCase, CustomerCaseService, Lpn, Resume, ResumeRequestState, ResumeType } from "@cg/shared";
import { MyCarglassNotificationStatus } from "../enums/my-carglass-toast-status.enum";
import { KtciResume, Login } from "../interfaces";
import { LoginService } from "../services/login.service";
import * as LoginActions from "./login.actions";
import * as LoginSelectors from "./login.selectors";

const eventActionKtciCode = "log-in-code";
const eventActionLicensePlate = "log-in-license-plate";
const eventNewKtciCode = "request-log-in-code";
const eventLabelFail = "fail";
const eventLabelResendFail = "not-available";
const eventLabelSuccess = "next/success";

@Injectable()
export class LoginEffects {
  public licencePlateNumberCheck$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.checkLicensePlateNumber),
      switchMap((action: { lpn: Lpn }) =>
        this.loginService.checkLicensePlateNumberCode(action.lpn).pipe(
          switchMap(({ resumeId, emailAddress }: Login) => {
            this.trackEvent(eventLabelSuccess);
            return of(
              LoginActions.licensePlateNumberCheckSuccessResultReceived({
                resumeId: resumeId
              }),
              LoginActions.setNotificationStatusToMailDeliverySuccess({ payload: { emailAddress } })
            );
          }),
          catchError((error: HttpErrorResponse) => {
            const status = error.status;
            const isResendStatus = status === 403;
            const errorObject = error.error;
            let maxAttempts = 0;
            this.trackLicensePlateSubmitEventOnError(isResendStatus);

            if (isResendStatus) {
              return this.handle403(errorObject);
            } else if (![403, 410].includes(status)) {
              this.navigateToErrorPage();
            } else {
              maxAttempts = this.getMaxAttempts(errorObject["failedAttempts"]);
            }
            return of(
              LoginActions.licensePlateNumberCheckFailureResultReceived({
                payload: {
                  maxAttempts,
                  apiErrorStatusCode: error.status
                }
              }),
              LoginActions.setNotificationStatus({ payload: MyCarglassNotificationStatus.WRONG_LPN })
            );
          })
        )
      )
    )
  );

  public loginWithKtciCode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.loginWithKtciCodeButtonClicked),
      switchMap((action: { resumeId: string; ktci: string }) =>
        this.loginService.requestKtciResumeWithResumeIdAndKtciCode(action.resumeId, action.ktci).pipe(
          map((payload: KtciResume) => {
            this.trackEvent(eventLabelSuccess, eventActionKtciCode);
            return LoginActions.loginWithKtciSuccessful({ payload });
          }),
          catchError((error: HttpErrorResponse) => {
            this.trackEvent(eventLabelFail, eventActionKtciCode);
            if (error.status === 410) {
              this.navigateToErrorPage();
            }
            const maxAttempts = this.getMaxAttempts(error.error["failedAttempts"]);
            return of(
              LoginActions.loginFailureResultReceived({
                payload: {
                  maxAttempts,
                  apiErrorStatusCode: error.status
                }
              }),
              LoginActions.setNotificationStatus({ payload: MyCarglassNotificationStatus.WRONG_KTCI })
            );
          })
        )
      )
    )
  );

  public loginWithKtciCodeSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.loginWithKtciSuccessful),
      switchMap((response: { payload: { customerCaseId: string } }) =>
        this.customerCaseService.getCustomerCase$(response.payload.customerCaseId)
      ),
      withLatestFrom(this.store.pipe(select(LoginSelectors.getKtciResume))),
      map(([customerCase, ktciResume]: [CustomerCase, KtciResume]) => {
        this.appointmentFacade.resetState();
        this.processFacade.resetState();
        this.store.dispatch(CustomerCaseActions.fetchCustomerCaseSuccess({ payload: customerCase }));

        const resume: Resume = {
          customerCaseId: customerCase.id,
          created: ktciResume.created,
          manualCarSelection: ktciResume.manualCarSelection,
          resumeType: ktciResume.resumeType,
          version: ktciResume.version,
          state: ktciResume.state as unknown as ResumeRequestState
        };

        if (ktciResume.resumeType === ResumeType.B2C_MYCARGLASS) {
          this.router.navigate(["/my-carglass/detail"]);
          return LoginActions.setResume({ resume });
        }

        this.store.dispatch(SharedActions.setResumeResponse({ payload: resume }));
        this.router.navigate(["/olb"]);
        return ResumeActions.loadResumeSuccess({ response: resume });
      })
    )
  );
  public sendMailAgain$ = createEffect(() =>
    this.actions$.pipe(
      ofType(LoginActions.sendKtciMailAgain),
      withLatestFrom(this.store.pipe(select(LoginSelectors.getLpn))),
      map(([_action, lpn]: [Action, Lpn]) => lpn),
      switchMap((lpn: Lpn) =>
        this.loginService.checkLicensePlateNumberCode(lpn).pipe(
          map(() => {
            this.trackEvent(eventLabelSuccess, eventNewKtciCode);
            return LoginActions.setNotificationStatus({
              payload: MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_AGAIN
            });
          }),
          catchError(() => {
            this.trackEvent("fail", eventNewKtciCode);
            return of(
              LoginActions.setNotificationStatus({
                payload: MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_NOT_POSSIBLE
              })
            );
          })
        )
      )
    )
  );

  private trackLicensePlateSubmitEventOnError(isResendStatus: boolean): void {
    const eventLabel = isResendStatus ? eventLabelResendFail : eventLabelFail;
    const eventAction = isResendStatus ? eventActionKtciCode : eventActionLicensePlate;
    this.trackEvent(eventLabel, eventAction);
  }

  private getMaxAttempts(failedAttempts: number): number {
    const maxAttempts = 3 - failedAttempts;
    if (maxAttempts <= 0) {
      this.navigateToErrorPage();
      return 0;
    }
    return maxAttempts;
  }

  private navigateToErrorPage(): void {
    this.router.navigate(["/login/error"]);
  }

  private trackEvent(eventLabel: string, eventAction: string = "log-in-license-plate"): void {
    this.gtmService.trackEvent({
      eventCategory: "my-carglass",
      eventAction,
      eventLabel
    });
  }

  private handle403(
    errorObject: [string: string | number]
  ): Observable<
    | ({ payload: MyCarglassNotificationStatus } & Action<"[Login/API] Set Toast Status">)
    | ({ payload: { resumeId: string } } & Action<"[Login/API] Ktci Already Deliverd After Lpn Check">)
  > {
    return of(
      LoginActions.ktciAlreadyDeliverdAfterLpnCheck({
        payload: { resumeId: errorObject["resumeId"] }
      }),
      LoginActions.setNotificationStatus({ payload: MyCarglassNotificationStatus.KTCI_MAIL_DELIVERY_NOT_POSSIBLE })
    );
  }

  // eslint-disable-next-line max-params
  public constructor(
    private readonly actions$: Actions,
    private readonly loginService: LoginService,
    private readonly customerCaseService: CustomerCaseService,
    private readonly gtmService: GoogleTagManagerService,
    private readonly router: Router,
    private readonly store: Store,
    private readonly processFacade: ProcessFacade,
    private readonly appointmentFacade: AppointmentFacade
  ) {}
}
